<template>
  <div v-if="!isLoading">
    <Back :to="`/eir-sets/${setId}`">Назад</Back>
    <div class="mb-2">
      <h1>Версия: {{ version.version }}</h1>
      <div class="d-flex flex-row align-center">
        <v-chip
          v-if="version"
          small
          label
          outlined
          color="primary"
          class="mr-1"
        >
          Опубликована
        </v-chip>
        <v-chip v-else small label outlined class="mr-1">Черновик</v-chip>
        <v-chip small label outlined color="primary" class="mr-1">
          <span class="font-weight-bold">Изменена:&nbsp;</span>
          {{ version.updated_at | formatDate }}
        </v-chip>
      </div>
    </div>
    <div class="caption mb-1">Набор: «{{ setName }}»</div>
    <div class="caption mb-2">Комментарий: {{ version.comment }}</div>
    <div class="mt-4">
      <v-text-field
        v-model="search"
        label="Поиск"
        outlined
        hide-details
        dense
        clearable
        prepend-inner-icon="mdi-folder-search-outline"
        style="max-width: 500px"
      />
    </div>
    <v-treeview
      v-if="tree"
      :open.sync="open"
      :items="tree"
      multiple-active
      :search="search"
      class="mt-1"
      dense
    >
      <template #prepend="{ item, open }">
        <v-sheet :class="[getColorClass(item), 'pl-1']">
          <v-icon :color="getIconColor(item)">
            {{ getIcon(item, open) }}
          </v-icon>
        </v-sheet>
      </template>
      <template #label="{ item }">
        <v-sheet class="px-2" :class="getColorClass(item)">
          {{ item.name }}
        </v-sheet>
      </template>
    </v-treeview>
  </div>
  <v-progress-linear v-else class="loader" color="primary" indeterminate />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

import yaml from 'js-yaml'
import * as jsonDiffPatch from 'jsondiffpatch/dist/jsondiffpatch.esm'

import prepareTree from '@/utils/prepareTree'
import handlePatch from '@/utils/handlePatch'

import Back from '@/components/Back'

export default {
  name: 'Versions',
  components: { Back },
  data: () => ({
    isLoading: true,
    open: [],
    patch: [],
    search: '',
  }),
  computed: {
    ...mapState('eirSets', ['eirSetInfo']),
    ...mapState('versions', ['versions']),
    ...mapGetters('versions', ['getVersionById']),
    setName: (vm) => vm.eirSetInfo?.name ?? '',
    setId: (vm) => vm.$route.params.setId,
    versionId: (vm) => vm.$route.params.versionId,
    version: (vm) => vm.getVersionById(vm.versionId),
    leftTree: (vm) => {
      const index = vm.versions.findIndex(
        (version) => version.id === vm.versionId
      )
      const previousFile = vm.versions[index + 1]?.file ?? ''
      const loaded = yaml.load(previousFile)
      if (!loaded) return []

      return vm.prepareTree(loaded, null, true)
    },
    rightTree: (vm) => {
      const currentFile = vm.version?.file ?? ''
      const loaded = yaml.load(currentFile)
      if (!loaded) return []

      return vm.prepareTree(loaded, null, true)
    },
    tree: (vm) => {
      if (!vm.leftTree || !vm.rightTree) return null

      return vm.diffAndPatch(vm.leftTree, vm.rightTree)
    },
  },
  watch: {
    patch(patch = []) {
      this.openNodes(patch)
    },
  },
  async mounted() {
    this.isLoading = true

    await this.fetchSet(this.setId)
    await this.fetchVersions({
      eir_set: this.setId,
    })

    this.isLoading = false
  },
  methods: {
    ...mapActions('eirSets', ['fetchSet']),
    ...mapActions('versions', ['fetchVersions']),
    prepareTree,
    diffAndPatch(leftTree, rightTree) {
      const delta = jsonDiffPatch
        .create({
          objectHash: function (obj) {
            return obj.name
          },
          textDiff: { minLength: 9999999 },
          arrays: { detectMove: false },
          propertyFilter: function (name) {
            return name !== 'id'
          },
          cloneDiffValues: true,
        })
        .diff(leftTree, rightTree)

      const patch = jsonDiffPatch.formatters.jsonpatch.format(delta)
      this.patch = patch

      return handlePatch(patch, leftTree, rightTree)
    },
    openNodes(patch) {
      patch?.forEach(({ path }) => {
        let pathArray = path.split('/').filter((item) => item !== '')
        let trace = cloneDeep(this.rightTree)

        if (pathArray.length > 1) pathArray.pop()

        pathArray.forEach((key) => {
          trace = trace?.[key]

          if (trace && Object.hasOwnProperty.call(trace, ['id'])) {
            if (!this.open.includes(trace.id)) this.open.push(trace.id)
          }
        })
      })
    },
    getIcon(item, open) {
      if (item.icon) return item.icon

      const iconList = {
        openedFolder: 'mdi-folder-open-outline',
        closedFolder: 'mdi-folder-text-outline',
        doc: 'mdi-text-short',
      }
      let iconType = 'doc'

      if (item.children) {
        iconType = open ? 'openedFolder' : 'closedFolder'
      }

      return iconList[iconType]
    },
    getColorClass(item) {
      const classList = {
        red: 'bg-text-color-red',
        green: 'bg-text-color-green',
      }

      return classList[item.color] ?? ''
    },
    getIconColor(item) {
      const classList = {
        red: 'error',
        green: 'success',
      }

      return classList[item.color] ?? ''
    },
  },
}
</script>

<style lang="scss" scoped>
.v-treeview {
  & :deep(.v-treeview-node__prepend),
  :deep(.v-treeview-node__content) {
    margin: 0 !important;
  }

  .bg-text-color-red {
    background-color: rgba(255, 82, 82, 0.2);
    color: #ff5252;
  }

  .bg-text-color-green {
    background-color: rgba(67, 160, 71, 0.2);
    color: #4caf50;
  }

  .bg-text-color-yellow {
    background-color: rgba(249, 168, 37, 0.2);
    color: #f9a825;
  }
}
</style>
