import { v4 as UUIDv4 } from 'uuid'

function prepareTree(data, key, root = false) {
  const variants = {
    array: () => prepareNode(key, prepareChildren('array', data)),
    object: () => {
      const mapped = Object.keys(data).map((fieldKey) => {
        const children = prepareChildren(
          computeType(data[fieldKey]),
          data[fieldKey],
          fieldKey
        )

        return prepareNode(fieldKey, children, data[fieldKey])
      })

      return mapped.filter((item) => item !== null)
    },
    primitive: () => prepareNode(key, null, data),
  }

  if (root) return variants.object()

  return variants[computeType(data)]()
}

function prepareNode(nodeKey, nodeChildren, item) {
  if (nodeKey === 'id') return null

  let node = {
    id: UUIDv4(),
    name: `${getName(item, nodeKey) ?? nodeKey}`,
  }

  if (nodeChildren) node.children = nodeChildren

  return node
}

function prepareChildren(type, fields, fieldKey) {
  if (type === 'array')
    return fields
      .map((item, index) => prepareNode(index, prepareTree(item, index), item))
      .filter((item) => item !== null)

  if (type === 'object') return prepareTree(fields, fieldKey)

  return null
}

function getName(item, nodeKey) {
  if (item === undefined) return null

  if (computeType(item) === 'primitive') return `${nodeKey}: ${item}`

  if (
    typeof nodeKey === 'string' &&
    (nodeKey.includes('Фильтр для требования') ||
      nodeKey.includes('Набор требований'))
  )
    return nodeKey

  const keyStr = ['наименование', 'название']
  const fieldKey = Object.keys(item).find((k) => {
    return keyStr.some((str) => k.toLowerCase().includes(str))
  })

  return item[fieldKey]
}

function computeType(item) {
  if (Array.isArray(item)) return 'array'
  else if (
    typeof item === 'object' &&
    item !== null &&
    item !== undefined &&
    Object.keys(item)?.length
  )
    return 'object'

  return 'primitive'
}

export default prepareTree
