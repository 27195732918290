<template>
  <router-link v-if="to" class="mb-3 back" :to="to">
    <v-icon class="mr-1" size="17">mdi-arrow-left</v-icon>
    <slot />
  </router-link>

  <div v-else class="mb-3 back d-flex align-center" @click="$router.go(-1)">
    <v-icon class="mr-1" size="18">mdi-arrow-left</v-icon>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Back',
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
.back {
  display: flex;
  color: #4d4d4d;
  line-height: 1;
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
}
</style>
