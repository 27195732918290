import cloneDeep from 'lodash/cloneDeep'

export default function handlePatch(patch, leftTree, rightTree) {
  let tree = cloneDeep(rightTree)
  /**
   * Массив, чтобы разом добавить все элементы в конце, иначе индексы из путей в patch будут неверные
   */
  let linkArray = []

  patch.forEach(({ op, path }) => {
    let [trace, parent, grandparent] = getItemFromTree(tree, path)

    const traceHasId = trace ? Object.hasOwnProperty.call(trace, ['id']) : false
    const parentHasId = Object.hasOwnProperty.call(parent, ['id'])
    const grandparentHasId = Object.hasOwnProperty.call(grandparent, ['id'])
    let object = {}

    if (traceHasId) object = trace
    else if (parentHasId) object = parent
    else if (grandparentHasId) object = grandparent

    if (op === 'add' || op === 'replace') {
      object.color = 'green'
      object.icon = 'mdi-plus'
    }

    let targetParent = tree

    if (parentHasId) targetParent = parent.children
    else if (grandparentHasId) targetParent = grandparent.children

    if (op === 'remove') addRemovedItem(targetParent, leftTree, path, linkArray)
  })

  linkArray.forEach(({ targetParent, items }) => {
    items.forEach(({ targetItem, item }) => {
      const index = targetParent.indexOf(targetItem)

      targetParent.splice(index !== -1 ? index : 0, 0, item)
    })
  })

  return tree
}

function addRemovedItem(parent, leftTree, path, linkArray) {
  const [value] = getItemFromTree(leftTree, path)
  const parentIndex = linkArray.findIndex(
    ({ targetParent }) => targetParent === parent
  )
  const newItem = {
    item: {
      ...value,
      id: `${value.id}--old`,
      color: 'red',
      icon: 'mdi-minus',
    },
  }
  if (parentIndex === -1) {
    linkArray.push({
      targetParent: parent,
      items: [newItem],
    })
  } else {
    linkArray[parentIndex].items.push(newItem)
  }
}

function getItemFromTree(tree, path) {
  const pathArray = path.split('/').filter((item) => item !== '')
  let trace = tree
  let greatGrandparent = null
  let grandparent = null
  let parent = null

  const variants = {
    [pathArray.length - 4]: (item) => (greatGrandparent = item),
    [pathArray.length - 3]: (item) => (grandparent = item),
    [pathArray.length - 2]: (item) => (parent = item),
  }
  pathArray.forEach((key, index) => {
    trace = trace?.[key]
    variants[index]?.(trace)
  })

  if (grandparent !== undefined && trace === undefined)
    return [null, grandparent, greatGrandparent ?? tree]

  return [trace, parent ?? tree, grandparent ?? tree]
}
